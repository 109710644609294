<template>
<div>
    <p>Click on the Shortcode to view the messages</p>

    <b-card title="Click on Shortcode">
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm }" :pagination-options="{
        enabled: true,
        perPage:pageLength
      }">
            <template slot="table-row" slot-scope="props">

                <!-- Column: Name -->
                <div v-if="props.column.field === 'short_code'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <b-link :to="{ name: 'apps-inbox-keywords', params: { id: props.row.short_code }}" class="font-weight-bold">
                        <span class="text-nowrap">{{ props.row.short_code }}</span>
                    </b-link>
                </div>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="props.row.status | shortCodeStatusColor">
                        {{ props.row.status | shortcodeStatus}}
                    </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                    <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                            </template>
                            <b-dropdown-item>
                                <feather-icon icon="SearchIcon" class="mr-50" />
                                <b-link :to="{ name: 'apps-inbox-keywords', params: { id: props.row.short_code } }" class="font-weight-bold">
                                <span>View</span>
                                </b-link>
                                
                            </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card>
</div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BLink
} from 'bootstrap-vue'
import {
    VueGoodTable
} from 'vue-good-table'
import store from '@/store/index'
import $ from 'jquery'
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BCard,
        BLink
    },
    data() {
        return {
            pageLength: 5,
            dir: false,
            columns: [{
                    label: 'ShortCode',
                    field: 'short_code',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search Shortcode',
                    },
                },
                {
                    label: 'Type',
                    field: 'sender_type'
                },
               

                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [{
                '13': 'Active',
                '11': 'Pending',
                '12': 'Rejected',
            }, {
                '13': 'light-success',
                '11': 'light-primary',
                '12': 'light-danger',
            }],
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Active: 'light-success',
                Pending: 'light-primary',
                Rejected: 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    mounted() {
        let vm = this
        vm.getAllsenderIDs()
    },
    methods: {
        getAllsenderIDs() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'inbox/v1/shortcode',
                type: "get",
                async: true,
                data: {
                    shortCode: "",
                    offset: "",
                    sort: "",
                    export: "",
                    limit: "",
                    start: "",
                    end: "",
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.rows = []
                    } else {
                        let result = response.data.data.data;
                        vm.rows = result
                        console.log("Items are here " + JSON.stringify(vm.rows))

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.rows = []
                }
            });
        }
    },
    filters: {
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        shortcodeStatus(value) {
            if (value == 11) {
                return "Pending"
            } else if (value == 4) {
                return "Active"
            } else {
                return "Disabled"
            }
        },
        shortCodeStatusColor(value) {
            if (value == 11) {
                return "light-warning"
            } else if (value == 4) {
                return "light-success"
            } else {
                return "light-danger"
            }
        }
    }

}
</script>
